@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;






.custom-swal-popup {
    @apply w-96 h-96 p-8 rounded-md bg-stone-200
}

.custom-swal-title {
    @apply  text-lg; 
}

.custom-swal-icon {
    @apply text-xl; 
}



@layer base {
	html {
		font-family: 'Inter', sans-serif;
		line-height: 1.3;
	}
	table {
	}
	th,
	td {
		@apply py-3 px-2.5 text-sm text-left border-y border-gray-200;
	}
	thead {
		@apply bg-neutral-100;
	}
	th {
		@apply font-semibold;
	}
	a {
		@apply text-sky-500 hover:underline;
	}
}


.MusicD {
    display: flex;
    flex-wrap: nowrap; /* Default value for flex-wrap */
}

/* Medium screen */
@media (max-width: 850px) {
    .MusicD {
        flex-direction: column;
    }
}

/* Large screen */
@media (min-width: 1024px) {
    .MusicD {
        flex-direction: row;
    }
}

.modal-con {
	background: url('/public/assets/Group 350.png');
	padding: 0;
	margin: 0;
	background-repeat: no-repeat;
	background-size: cover;
	
}
a{
	text-decoration: none;
}

.dash{
	overflow-x: scroll;
}

.musicd{
	background-image: url('/public/assets/bg.png');
	background-repeat: no-repeat;
	background-size: cover;
}

.connect{
	background-image: url('/public/assets/bg.png');
	background-repeat: no-repeat;
	background-size: cover;
}

.modal-header .close {
    color: black;
    opacity: 1; 
}

